var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"authoring-wrapper"},[_c('div',{staticClass:"flex h-full"},[_c('ProgressBar',{attrs:{"stepIndex":_vm.stepIndex,"surveyID":_vm.surveyID,"stepsType":_vm.stepsType,"conciergeAssistance":_vm.stepsType === 'map-maker' ? false : true}}),_c('section',{staticClass:"wrapper"},[_c('a-breadcrumb',{staticClass:"breadcrum breadcrum--authoring"},[(_vm.user !== null && _vm.user.SkinIdentifier !== 'pg')?[_c('a-breadcrumb-item',[_c('a',{attrs:{"href":"/"}},[_vm._v(_vm._s(_vm.client.ClientName))])]),_c('a-breadcrumb-item',[_c('a',{attrs:{"href":"/products"}},[_vm._v("Products")])]),_c('a-breadcrumb-item',[_vm._v(_vm._s(_vm.breadcrumbTitle))])]:_vm._e()],2),_c('div',{staticClass:"wrapper__inner"},[_c('div',{staticClass:"page-title"},[_c('icon-base',{attrs:{"width":32,"height":33,"viewBox1":32,"viewBox2":33,"iconName":"adjustments","iconColor":"transparent"}},[_c('icon-adjustments')],1),_vm._m(0)],1),_c('div',{staticClass:"box-wrapper box-wrapper--settings-form"},[_c('p',{staticClass:"font-size-14 mb-20"},[_vm._v("*All fields are required.")]),_c('div',{staticClass:"box-wrapper__inner"},[_c('div',{staticClass:"input-group"},[_c('label',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s1-studyname'].quiz)}}),_c('a-tooltip',[_c('template',{slot:"title"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s1-studyname'].tip)}})]),_c('a-icon',{attrs:{"type":"info-circle"}})],2)],1),_c('div',{staticClass:"position-r"},[(_vm.showSkeleton)?_c('Skeleton',{attrs:{"borderRadius":16}}):_vm._e(),_c('a-input',{attrs:{"placeholder":_vm.surveyTypeID === 108
                      ? ("eg: Map Maker Test " + _vm.curDate)
                      : ("eg: Pack Test " + _vm.curDate)},model:{value:(_vm.surveyName),callback:function ($$v) {_vm.surveyName=$$v},expression:"surveyName"}})],1)]),(_vm.showBrandName)?_c('div',{staticClass:"input-group"},[_c('label',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s1-brand'].quiz)}}),_c('a-tooltip',[_c('template',{slot:"title"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s1-brand'].tip)}})]),_c('a-icon',{attrs:{"type":"info-circle"}})],2)],1),_c('div',{staticClass:"position-r"},[(_vm.showSkeleton)?_c('Skeleton',{attrs:{"borderRadius":16}}):_vm._e(),_c('a-input',{attrs:{"placeholder":"eg: AAA"},model:{value:(_vm.brandName),callback:function ($$v) {_vm.brandName=$$v},expression:"brandName"}})],1)]):_vm._e(),_c('div',{staticClass:"input-group"},[_c('label',[(_vm.stepsType !== 'map-maker')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s1-packtest-country'].quiz)}}):_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s1-packtest-country'].quizStudy)}}),_c('a-tooltip',[_c('template',{slot:"title"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s1-packtest-country'].tip)}})]),_c('a-icon',{attrs:{"type":"info-circle"}})],2)],1),_c('div',{staticClass:"country position-r"},[(_vm.showSkeleton)?_c('Skeleton',{attrs:{"borderRadius":16}}):_vm._e(),_c('a-select',{key:_vm.selectDropdownsKey,staticClass:"fullWidth",attrs:{"placeholder":"Please select country","option-filter-prop":"children","show-search":"","filter-option":_vm.filterCountry,"open":_vm.openCountry,"default-value":_vm.editableSurvey
                      ? _vm.editableSurvey.SurveyConfig.Country
                      : undefined},on:{"change":_vm.selectCountry,"focus":_vm.openDropdown,"blur":function($event){_vm.openCountry = false},"select":function($event){_vm.openCountry = false}}},_vm._l((_vm.markets),function(market){return _c('a-select-option',{key:market.MarketId,attrs:{"value":market.MarketId}},[_vm._v(" "+_vm._s(market.MarketName)+" ")])}),1)],1)]),_c('div',{staticClass:"input-group"},[_c('label',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s1-language'].quiz)}}),_c('a-tooltip',[_c('template',{slot:"title"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s1-language'].tip)}})]),_c('a-icon',{attrs:{"type":"info-circle"}})],2)],1),_c('div',{staticClass:"position-r"},[(_vm.showSkeleton)?_c('Skeleton',{attrs:{"borderRadius":16}}):_vm._e(),_c('a-select',{key:Math.random(),staticClass:"fullWidth",attrs:{"show-search":"","placeholder":"Please select country first to get related language list","default-value":_vm.langs[_vm.langIndex]
                      ? _vm.langs[_vm.langIndex].LucidLanguageName
                      : undefined},on:{"change":_vm.selectLang}},_vm._l((_vm.langs),function(lang,index){return _c('a-select-option',{key:index,attrs:{"value":index}},[_vm._v(" "+_vm._s(lang.LucidLanguageName)+" ")])}),1)],1)]),_c('div',{staticClass:"input-group"},[_c('label',[(_vm.stepsType !== 'map-maker')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s1-packtest-category'].quiz)}}):_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s1-packtest-category'].quizStudy)}}),_c('a-tooltip',[_c('template',{slot:"title"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s1-packtest-category'].tip)}})]),_c('a-icon',{attrs:{"type":"info-circle"}})],2)],1),_c('div',{staticClass:"position-r"},[(_vm.showSkeleton)?_c('Skeleton',{attrs:{"borderRadius":16}}):_vm._e(),_c('a-tree-select',{staticClass:"fullWidth",attrs:{"replaceFields":{
                    children: 'Children',
                    title: 'CategoryName',
                    key: 'CategoryId',
                    value: 'CategoryId',
                  },"dropdown-style":{ maxHeight: '400px', overflow: 'auto' },"tree-data":_vm.categories,"allowClear":true,"placeholder":"Please select appropriate category"},model:{value:(_vm.categoryID),callback:function ($$v) {_vm.categoryID=$$v},expression:"categoryID"}})],1)]),(_vm.showStudyType)?_c('div',{staticClass:"input-group"},[_c('label',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s1-packtest-study-type'].quiz)}}),_c('a-tooltip',[_c('template',{slot:"title"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s1-packtest-study-type'].tip)}})]),_c('a-icon',{attrs:{"type":"info-circle"}})],2)],1),_c('div',{staticClass:"position-r"},[(_vm.showSkeleton)?_c('Skeleton',{attrs:{"borderRadius":16}}):_vm._e(),_c('a-select',{key:_vm.selectDropdownsKey,staticClass:"fullWidth",attrs:{"placeholder":"Please specify if Packaging restage or Shelf Optimization","option-filter-prop":"children","show-search":"","filter-option":_vm.filterCountry,"default-value":_vm.editableSurvey
                      ? _vm.editableSurvey.SurveyConfig.studyType
                      : undefined},on:{"change":function($event){return _vm.setStudyType($event)}}},_vm._l(([
                      { name: 'Pack', label: 'Packaging restage' },
                      { name: 'SBD', label: 'Shelf Optimization' } ]),function(study){return _c('a-select-option',{key:study.name,attrs:{"value":study.name}},[_vm._v(" "+_vm._s(study.label)+" ")])}),1)],1)]):_vm._e()])]),_c('StepNaviBar',{attrs:{"stepIndex":_vm.stepIndex,"nextDisable":_vm.nextDisable,"cancelButton":_vm.editableSurvey ? false : true,"conciergeAssistance":_vm.surveyTypeID === 108 ? false : true,"stepsType":_vm.surveyTypeID === 108 ? 'map-maker-test' : 'pack',"nextBtnTooltip":"Go to Stimuli"},on:{"nextHandler":_vm.nextStep,"cancel:draft":_vm.cancelDraft,"editable-survey-updated":_vm.handleEditableSurveyUpdate}})],1)],1)],1),_c('CompletionProgress',{attrs:{"checkList":_vm.quizs}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-title__text"},[_c('h1',[_vm._v("General Settings")]),_c('span',[_vm._v("General Study information")])])}]

export { render, staticRenderFns }